import Axios from 'axios'
import router from '@/router'
const AppAPI = process.env.NODE_ENV == "production"? `https://${window.location.hostname}`: `http://${window.location.hostname}:3001`

export default {
    user: { authenticated: false },
    //авторизация и запись cookies
    authenticate(context, credentials, redirect) {
        Axios.post(`${AppAPI}/api/v1/auth`, credentials)
            .then(({ data: { data } }) => {
                context.$cookie.set('token', data.token, { expires: '2h' })
                context.$cookie.set('expires', Date.now() + 60*60*2*1000, { expires: '2h' })
                context.validLogin = true
                this.user.authenticated = true

                if (redirect) router.push(redirect)
            }).catch(({ response: { data } }) => {
                context.toast = true
                context.message = data.message
            })
    },
    signout (context, redirect) {
        context.$cookie.delete('token')
        context.$cookie.delete('user_id')
        context.$cookie.delete('expires')
        this.user.authenticated = false    
        if (redirect) router.push(redirect)
    },
    //получить и распарсить куки
    getCookie(name) {
        let matches = document.cookie.match(new RegExp(
            "(?:^|; )" + name.replace(/([\\.$?*|{}\\(\\)\\[\\]\\\/\\+^])/g, '\\$1') + "=([^;]*)"
        ));
        return matches ? decodeURIComponent(matches[1]) : undefined;
    },
    //проверка на действительность сессии
    checkAuthentication() {
        this.user.authenticated = document.cookie && (this.getCookie('expires') > Date.now())
        return this.user.authenticated
    },

    /*getAuthenticationHeader(context) {
        return `Bearer ${context.$cookie.get('token')}`
    },*/
    rand() {
        return Math.random().toString(36).substr(2); 
    },
    
    token() {
        return this.rand() + this.rand(); 
    },
    
    
    async sendRequest(context, path, type, params = {}) {        
        this.checkAuthentication()
        if (this.user.authenticated) {
            let config = {
                method: type,
                url: `${AppAPI}${path}`,
                headers:
                {
                    'Authorization': `Bearer ${context.$cookie.get('token')}`,
                },
            }
            if (params) {
                config.data = params;                
                if (params.filedata) {
                    let form_data = new FormData();
                    for ( let key in params ) {
                        form_data.append(key, params[key]);
                    }
                    config.data = form_data;
                }
                if(params.new_images){
                    let form_data = new FormData();
                    for ( let key in params ) {
                        if(key!="new_images"){
                            if(Array.isArray(params[key])){
                                form_data.append(key, JSON.stringify(params[key]));
                            }else{
                                form_data.append(key, params[key]);
                            }
                        }
                    }
                    for (let i = 0 ; i < params.new_images.length ; i++) {
                        form_data.append("new_images", params.new_images[i]);
                    }
                    config.data = form_data;
                    /*for (var value of config.data.values()) {
                        console.log(value);
                     }*/
                }
            }
            return Axios(config).catch(error=>{
                return {
                    data:
                    {
                        succsess:false,
                        message:error
                    }
                }
            });
        } else {
            //редирект на страницу авторизации при истечении сессии
            router.push('/admin')
        }
        return false
    },
    async sendRequestNotAuth(context, path, type, params = {}) {    
              let config = {
                method: type,
                url: `${AppAPI}${path}`,
                headers:
                {
                    'Content-Type': 'application/json; charset=utf-8'
                },
            }
            if (params) {
                config.data = params;
            }
            if (params) {
                config.data = params;
                if (params.filedata) {
                    let form_data = new FormData();
                    for ( var key in params ) {
                        form_data.append(key, params[key]);
                    }
                    config.data = form_data;
                }
            }
            return Axios(config).catch(error=>{
                return {
                    data:
                    {
                        succsess:false,
                        message:error
                    }
                }
            });
    },
    download(id,download){
        return AppAPI+'/api/v1/download/'+id+(download?'?download=true':'')
    },
    priceRub(price,valuta) {
        let currency_val=valuta  
        if(currency_val){
            return (Math.round(price/currency_val/100)*100).toFixed(0)
        }else{
            return null
        }     
        
    },
}
