<template>
    <div>
        <hr>
        <h4>В наличии</h4>
        <hr>
        <b-form class=" mb-2 ml-3">
          <b-form-select v-model="selected_stock" :options="stocks" @change="changeStockProduct()"></b-form-select>            
      </b-form> 
      <b-toast
      :title="toast.title"
      v-model="toast.show"
      :variant="toast.variant"
      autoHideDelay="5000"
    >{{ toast.message }}</b-toast>      
    </div>
  </template>
  <script>
  import Authentication from "@/components/pages/Authentication";
  
  export default {
    props: ["product_id","stock_id"],
    data() {
      return {
        selected_stock:null,
        stocks:[],
        toast: {
        show: false,
        title: "",
        variant: "",
        message: "",
      },
      };
    },
    created(){
      this.selected_stock = this.stock_id
    },
    mounted() {
      this.getAllStocks();
    },
    methods: {  
      showToast(data) {
      if (data) {
        if (data.success) {
          this.toast = {
            show: true,
            title: "Выполнено",
            variant: "success",
            message: data.message,
          };
        } else {
          this.toast = {
            show: true,
            title: "Ошибка",
            variant: "danger",
            message: data.message,
          };
        }
      }
    },
      async getAllStocks() {
      let { data } = await Authentication.sendRequest(
        this,
        "/api/v1/stockproducts",
        "get"
      );
      if (data) {
        this.stocks = [...data.map(stock => {return { value:stock.id, text: stock.name }}), {value: null, text:'нет в наличии'}];
      }
    },    
      
      async changeStockProduct(){
        let { data } = await Authentication.sendRequest(
        this,
        "/api/v1/changeproductstockproduct",
        "post",
        {stock: this.selected_stock, product_id: this.product_id }
      );
        this.showToast(data);      
      }
    },
  };
  </script>
  <style lang="scss">
  </style>